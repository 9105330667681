import React, {Component} from 'react';
import './HomePage.css';
import {Container, Row, Col} from 'react-bootstrap';
import ContactForm from '../contactform/ContactForm';
import InstagramPost from '../instagram-post/InstagramPost';
import HomeJumbo from '../home-jumbo/HomeJumbo';

class HomePage extends Component {

  render() {
    return (
      <div className="HomePage">
        <Container fluid>
          <Row className="bg-opacity">
            <div className="content">
              <HomeJumbo />
            </div>
          </Row>
          <Row>
            <Col md={{ span: 5, offset: 1 }}>
              <br></br>
              <h1 className="message-title">
                <b>
                  Organically derived.
                </b>
              </h1>
            </Col>
            <Col md={{ span: 5, offset: 0 }}>
              <br></br>
              <p className="message">
                FarmTab is short for farm to table because we close the distance between farms to your restaurant tables. By growing organic produce in an urban setting, we are able to ensure freshness and speed with every delivery.
              </p>
              <br></br>
              <p className="message">
                The production starts with clean traceable seeds grown in optimal conditions which results in nutrient dense produce. Our microgreens are organically concentrated meaning they contain, higher mineral and antioxidant levels than the same quantity of mature greens. Coming in a vast range of colours, FarmTab's produce will give that extra "pop' in aesthetic and taste to compliment any dish.
              </p>
              <br></br>
            </Col>
          </Row>
          <div id="Anchor-HomePage-ContactUs">
            <Row className="contact-row">
              <Col md={{ span: 5, offset: 2 }}>
                <h1 className="ContactForm-heading">Get in touch with us!</h1>
              </Col>
            </Row>
          </div>
          <Row className="contact-row">
            <Col md={{ span: 5, offset: 2 }}>
              <ContactForm />
            </Col>
            <Col md={{ span: 2, offset: 1 }}>
              <InstagramPost />
            </Col>
          </Row>
          <Row>
            <p></p>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomePage;