import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import './HomeJumbo.css';
import {Link} from 'react-router-dom';

class HomeJumbo extends Component {

  render() {
    return (
      <div className="HomeJumbo">
            <h1 className="Jumbo-heading">
                Farm to table.
            </h1>
            <br></br>
            <p className="Jumbo-message">
                Delivering fresh, local produce from our farm to your tables.
            </p>
            <br></br>
            <p>
                <Link to="/our-products">
                    <Button className="Jumbo-button">Our products</Button>
                </Link>
            </p>
      </div>
    );
  }
}

export default HomeJumbo;