import React, {Component} from 'react';
import {Form, Button, Alert} from 'react-bootstrap';
import './ContactForm.css';
import axios from 'axios';

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      name: '',
      email: '',
      message: '',
      messageSent: '',
    }
  }

  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();
    axios({
      method: "POST",
      url: "http://localhost:5000/api/mail",
      data: this.state
    }).then((response) => {
      if (response.data.status === 'success') {
        this.setState({messageSent: true})
      } else {
        this.setState({messageSent: false})
      }
    })
  }

  render() {
    const messageStatus = this.state.messageSent;
    let messageDisplay;
    if (messageStatus === '') {
      messageDisplay = <Form id="ContactForm" onSubmit={this.handleSubmit.bind(this)} method="POST">
                          <Form.Group controlId="formBasicEmail">
                              <Form.Label className="ContactForm-label">Name</Form.Label>
                              <Form.Control type="text" size="lg" required value={this.state.name} onChange={this.onNameChange.bind(this)} />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                              <Form.Label className="ContactForm-label">Email</Form.Label>
                              <Form.Control type="email" size="lg" required  value={this.state.email} onChange={this.onEmailChange.bind(this)}/>
                          </Form.Group>
                          <Form.Group controlId="ContactForm.ControlTextarea">
                              <Form.Label>Message</Form.Label>
                              <Form.Control as="textarea" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                          </Form.Group>
                          <Form.Group controlId="ContactForm.SubmitButton">
                              <Button className="ContactForm-button" type="submit" size="lg" >
                                  Submit
                              </Button>
                          </Form.Group>
                      </Form>
    } else if (messageStatus === false) {
      messageDisplay = <Alert variant="danger">
                          <b>Error</b>: Something went wrong! Please try again later. 
                        </Alert>
    } else {
      messageDisplay = <Alert variant="success">
                          This is a success alert—check it out!
                        </Alert>
    }
    return (
      <div className="ContactForm">
        {messageDisplay}
      </div>
    );
  }
}

export default ContactForm;