import React, {Component} from 'react';
import {Container, Row, Col, Card, Table, Button} from 'react-bootstrap';
import GreenTatsoi from './product-pictures/green-tatsoi.png';
import LemonBalm from './product-pictures/lemon-balm.png';
import RedAmaranth from './product-pictures/chard-rainbow.png';
import RainbowChard from './product-pictures/red-amaranth.png';
import RedRadish from './product-pictures/red-radish.png';
import ProductsJumbo from '../products-jumbo/ProductsJumbo';
import './OurProducts.css';

class OurProducts extends Component {

  render() {
    return (
      <div className="OurProducts">
        <Container fluid>
            <Row className="ProductsJumbo-bg-opacity">
                <div className="ProductsJumbo-content">
                    <ProductsJumbo />
                </div>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <h1 className="OurProducts-headings">
                        Microgreens
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 1 }}>
                    <Card border="white" style={{ width: '10rem' }}>
                        <Card.Img variant="top" src={GreenTatsoi} />
                        <Card.Body>
                            <h6>Green Tatsoi</h6>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={{ span: 3, offset: 1 }}>
                    <Card border="white" style={{ width: '10rem' }}>
                        <Card.Img variant="top" src={LemonBalm} />
                        <Card.Body>
                            <h6>Lemon Balm</h6>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={{ span: 3, offset: 1 }}>
                    <Card border="white" style={{ width: '10rem' }}>
                        <Card.Img variant="top" src={RedAmaranth} />
                        <Card.Body>
                            <h6>Red Amaranth</h6>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 1 }}>
                    <Card border="white" style={{ width: '10rem' }}>
                        <Card.Img variant="top" src={RainbowChard} />
                        <Card.Body>
                            <h6>Rainbow Chard</h6>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={{ span: 3, offset: 1 }}>
                    <Card border="white" style={{ width: '10rem' }}>
                        <Card.Img variant="top" src={RedRadish} />
                        <Card.Body>
                            <h6>Red Radish</h6>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <h1 className="OurProducts-headings">
                        Price and Packaging
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 1, offset: 0 }}></Col>
                <Col md={{ span: 10, offset: 0 }}>
                    <Table striped bordered hover className="OurProducts-packaging-table">
                        <thead>
                            <tr>
                                <th>Packaging</th>
                                <th>Description</th>
                                <th>Size (mm)</th>
                                <th>Price per unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Pots</td>
                                <td>Produce grown in modiform pots and sold individually</td>
                                <td>90 Ø x 68</td>
                                <td>$3.50</td>
                            </tr>
                            <tr>
                                <td>Trays</td>
                                <td>Sold and grown in plastic trays - equivalent to the size of 10 pots</td>
                                <td>400 x 280 x 50</td>
                                <td>$30.00</td>
                            </tr>
                            <tr>
                                <td>Punnets</td>
                                <td>Grown in trays, harvested and packaged into plastic containers - equivalent to 10 pots</td>
                                <td>172 x 132 x 40</td>
                                <td>$35.00</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col md={{ span: 1, offset: 0 }}></Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 0 }}></Col>
                <Col md={{ span: 6, offset: 0 }}>
                    <div className="OurProducts-packaging-image">
                    </div>
                </Col>
                <Col md={{ span: 3, offset: 0 }}></Col>
            </Row>
            <Row>
                <Col md={{ span: 4, offset: 0 }}></Col>
                <Col md={{ span: 4, offset: 0 }}>
                    <a href="https://drive.google.com/file/d/1prkcMb5lCBOsM0-Yy4aX1y1ihMVQnQUN/view?usp=sharing">
                        <Button className="Jumbo-button">Download our product list</Button>
                    </a>
                </Col>
                <Col md={{ span: 4, offset: 0 }}></Col>
            </Row>
            <Row>
                <p></p>
            </Row>
        </Container>
      </div>
    );
  }
}

export default OurProducts;