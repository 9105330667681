import React, {Component} from 'react';
import './BlogJumbo.css';
class BlogJumbo extends Component {

  render() {
    return (
      <div className="BlogJumbo">
            <h1 className="Jumbo-heading">
                Blog
            </h1>
            <br></br>
            <p className="Jumbo-message">
                Stay tuned as our blog content is coming soon.
            </p>
            <p className="Jumbo-message">
                ... in the meantime, keep in touch with us through our socials! 
            </p>
            <br></br>
      </div>
    );
  }
}

export default BlogJumbo;