import React from "react";
import { MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {Row, Col} from 'react-bootstrap';
//import InstagramPost from '../instagram-post/InstagramPost';
import {FaInstagram, FaFacebook} from 'react-icons/fa'
import {FiMail} from 'react-icons/fi'
import './FooterPage.css';
import {Link} from 'react-router-dom';

const FooterPage = () => {
  return (
    <Row className="footer-bg-opacity">
      <div className="footer-content">
    <MDBFooter color="blue" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <Col md={{ span: 3, offset: 1 }}>
            <h3 className="title"><b>Navigation</b></h3>
            <p>
              <Link className="FootPage-Link" to="/blog"> 
                Blog
              </Link>
            </p>
            <p>
              <Link className="FootPage-Link" to="/our-products"> 
                Our Products
              </Link>
            </p>
            <p>
              <Link className="FootPage-Link" to="/about-us"> 
                About Us
              </Link>
            </p>
          </Col>
          <Col className="footer-logo-column" md={{ span: 4, offset: 0 }}>
            <h1>FarmTab</h1>
            <i>Our farm to your tables.</i>
            <p>
              <a href="https://www.instagram.com/farmtab/">
                <FaInstagram className="footer-social" />
              </a>
              &emsp;
              <a href="https://www.facebook.com/FarmTab-115844496830100/">
                <FaFacebook className="footer-social" />
              </a>
            </p>
          </Col>
          <Col md={{ span: 3, offset: 1 }}>
            <h3 className="title"><b>Contact</b></h3>
              <p className="footer-social-email">
                <a href="mailto:admin@farmtab.com.au">
                <FiMail className="footer-social"/>
                </a>
              </p>
          </Col>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <br></br><br></br><br></br>
          <i>2020. All Rights Reserved FarmTab NSW Australia </i> | <i>ABN 23 465 900 669 </i>
        </MDBContainer>
      </div>
    </MDBFooter>
    </div>
    </Row>
  );
}

export default FooterPage;