import React, {Component} from 'react';
import './AboutUsJumbo.css';
class AboutUsJumbo extends Component {

  render() {
    return (
      <div className="AboutUsJumbo">
            <h1 className="Jumbo-heading">
                About Us
            </h1>
            <br></br>
            <p className="Jumbo-message">
            </p>
            <br></br>
      </div>
    );
  }
}

export default AboutUsJumbo;