import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import BlogJumbo from './BlogJumbo';
import './Blog.css';

class Blog extends Component {

  render() {
    return (
      <div className="Blog">
        <Container fluid>
            <Row className="Blog-bg-opacity">
                <div className="Blog-content">
                    <BlogJumbo />
                </div>
            </Row>
            <Row>
                <p></p>
            </Row>
        </Container>
      </div>
    );
  }
}

export default Blog;