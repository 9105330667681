import React, {Component} from 'react';
import './App.css';
import {Container, Row, Col} from 'react-bootstrap';
import NavigationBar from './components/navigationbar/NavigationBar';
import HomePage from './components/home-page/HomePage';
import FooterPage from './components/footer-page/FooterPage';
import OurProducts from './components/our-products/OurProducts';
import {BrowserRouter, Route} from 'react-router-dom';
import AboutUs from './components/about-us/AboutUs';
import Blog from './components/blog/Blog';

class App extends Component {


  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Container fluid>
            <Row>
              <Col>
                <NavigationBar />
              </Col>
            </Row>
          </Container>
          <Route exact path="/" render={props => (
            <React.Fragment>
              <HomePage />
            </React.Fragment>
          )}/>
          <Route exact path="/blog" render={props => (
            <React.Fragment>
              <Blog />
            </React.Fragment>
          )}/>
          <Route exact path="/our-products" render={props => (
            <React.Fragment>
              <OurProducts />
            </React.Fragment>
          )}/>
          <Route exact path="/about-us" render={props => (
            <React.Fragment>
              <AboutUs />
            </React.Fragment>
          )}/>
          <Container fluid>
            <Row>
              <Col>
                <FooterPage />
              </Col>
            </Row>
          </Container>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;