import React, {Component} from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Logo from './logo.png';
import './NavigationBar.css';

class NavigationBar extends Component {


  render() {
    return (
      <div className="NavigationBar">
        <Navbar expand="lg" variant="light" bg="white">
          <Link to="/"> 
            <Navbar.Brand><img className="NavigationBar-logo" alt="farmtab-logo" src={Logo}/></Navbar.Brand>
          </Link>
          <Nav className="ml-auto" activeKey="/home">
            <Nav.Item>
              <Nav.Link><Link className="NavigationBar-link" to="/blog">Blog</Link></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link><Link className="NavigationBar-link" to="/our-products">Products</Link></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link><Link className="NavigationBar-link" to="/about-us">About Us</Link></Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default NavigationBar;