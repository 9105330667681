import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import AboutUsJumbo from './AboutUsJumbo';
import './AboutUs.css';

class AboutUs extends Component {

  render() {
    return (
      <div className="AboutUs">
        <Container fluid>
            <Row className="AboutUs-bg-opacity">
                <div className="AboutUs-content">
                    <AboutUsJumbo />
                </div>
            </Row>
            <Row>
                <div className="AboutUs-heading">
                    <h1>
                        Our Story
                    </h1>
                </div>
            </Row>
            <Row>
                <Col md={{ span: 5, offset: 1 }}>
                    <div className="AboutUs-message-image-friends">
                    </div>
                </Col>
                <Col md={{ span: 5, offset: 0 }}>
                <p className="AboutUs-message">
                    Cofounders Eric and Kevin met at the University of New South Wales while studying Mechical Engineering. Throughout university, both dreamed of starting a business - the countless discussions and day-dreams of one day growing a company from scratch. 
                </p>
                <p className="AboutUs-message">
                    Their paths diverged only to reconnect one day starting FarmTab with all their knowledge and experience. Eric having gained years of experience in consulting and project management while Kevin developing knowledge in manufacturing and software, allowed FarmTab to be where it is today - turning their once day-dream into their reality.
                </p>
                <p className="AboutUs-message">
                </p>
                <br></br>
                </Col>
            </Row>
            <Row>
                <div className="AboutUs-heading">
                    <h1>
                        FarmTab
                    </h1>
                </div>
            </Row>
            <Row>
                <Col md={{ span: 1, offset: 0 }}>
                </Col>
                <Col md={{ span: 10, offset: 0 }}>
                    <p className="AboutUs-message">
                        We are vegetable lovers! FarmTab was started by growing vegetables in our very own backyard. It was not long until we realised that we could get the same nutrients for less through microgreens. Microgreens are a tiny form of edible greens produced from vegetable, herb or other plants. Often used as a fresh flavor ingredient, microgreens add a visual appeal, while being a great addition to any meal due to it’s high nutrient density. It is through learning about microgreens where FarmTab started it's very own grow. 
                    </p>
                    <p className="AboutUs-message">
                        The vision of FarmTab is to shorten the distance between our farm to our customer's tables. We achieve this by growing quality produce in the heart of Sydney. To provide the best and most value for our customers, we perform rigorous testing to understand the deep grow cycles before moving orders to our production.
                    </p>
                    <p className="AboutUs-message">
                        FarmTab is built by Eric and Kevin, but more importantly, it is built through our community. FarmTab is where it is today by connecting with local businesses and taking the time to listen to their stories and feedback. We aim to create a community that supports local businesses through providing fresh produce. 
                    </p>
                    <br></br>
                </Col>
                <Col md={{ span: 1, offset: 0 }}>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default AboutUs;