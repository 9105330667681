import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import './ProductsJumbo.css';
import {HashLink} from 'react-router-hash-link';

class ProductsJumbo extends Component {

  render() {
    return (
      <div className="ProductsJumbo">
            <h1 className="ProductJumbo-heading">
                Our Products
            </h1>
            <br></br>
            <p className="ProductJumbo-message">
                FarmTab's range of fresh quality produce is always growing.
            </p>
            <br></br>
            <p>
                <HashLink to="/#Anchor-HomePage-ContactUs">
                    <Button className="Jumbo-button">Contact us to order</Button>
                </HashLink>
            </p>
      </div>
    );
  }
}

export default ProductsJumbo;